<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <ayl-table :table="table" @selection-change="onSelectionChange">
          <div slot="ctrl-button">
            <!-- {{selectionData}} -->
            <el-button class="btn" style="margin-left: 20px" @click="addSIM">+ 新增</el-button>
            <el-button plain @click="onDelete" style="float:right">卡作废</el-button>
            <!-- <el-button plain @click="onExport" style="float:right">导出</el-button> -->
          </div>
        </ayl-table>
      </div>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";

export default {
  data() {
    const vm = this;
    return {
      nav: [{ name: "SIM卡管理" }],
      btnLoading: false,
      selectionData: [], //勾选的数据
      table: {
        api: vm.$api_hw.simManagement_listSimInfoByQueryCondition,
        query: {
          simStatus: [],
          serviceProvider: null,
          expireDateStart: null,
          expireDateEnd: null,
          queryContent: null
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "select",
            title: "运营商",
            model: "serviceProvider",
            placeholder: "请选择",
            option: vm.$enums.ServiceProvider.allList()
          },
          {
            type: "dateSection",
            title: "到期时间",
            date: [],
            modelName: ["expireDateStart", "expireDateEnd"]
          },
          {
            type: "input",
            model: "queryContent",
            placeholder: "车牌号/SIM卡号"
          }
        ],
        columns: [
          {
            type: "selection",
            width: "50px"
          },
          {
            title: "序号",
            width: "50px",
            $index: "index"
          },
          {
            title: "SIM卡号",
            key: "simNumber",
            showTooltip: true,
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true
                    },
                    on: {
                      click: vm.simInformation.bind(this, ctx.row)
                    }
                  },
                  ctx.row.simNumber || "- -"
                )
              ]);
            }
          },
          {
            title: "运营商",
            key: "serviceProviderDes",
            width: "100px"
          },
          {
            title: "所属标段",
            key: "bidName",
          },
          {
            title: "套餐",
            key: "pricePackage",
            width: "100px"
          },
          {
            title: "到期时间",
            key: "expireDate",
            filter: "str2ymd",
            showTooltip: true
          },
          {
            title: "使用状态",
            key: "useStatus",
            width: "100px"
          },
          {
            title: "操作",
            width: "100px",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true
                    },
                    on: {
                      click: vm.editSIM.bind(this, ctx.row)
                    }
                  },
                  "编辑"
                )
              ]);
            }
          }
        ]
      }
    };
  },
  methods: {
    async onRefresh() {
      await this.$search(this.table);
    },
    /**
     * 编辑设备
     */
    goEdit(value) {
      this.$router.push({
        path: "/district-management/equipment-management/edit-equipment",
        query: {
          //deviceId: value.deviceInfoVOIOV.deviceId,
          type: "edit"
        }
      });
    },

    //导出
    exportClick() {},
    //SIM卡信息
    simInformation(value) {
      this.$router.push({
        path: "/district-management/sim-management/sim-information",
        query: {
          simNumber: value.simNumber,
        }
      });
    },

    //新增设备
    addSIM() {
      this.$router.push({
        path: "/district-management/sim-management/add-sim"
      });
    },
    //编辑设备
    editSIM(value) {
      this.$router.push({
        path: "/district-management/sim-management/edit-sim",
        query: {
          simNumber: value.simNumber,
          type: "edit",
          status: value.status
        }
      });
    },
    onSelectionChange(i) {
      if (i.some((d) => d.useStatus !== '备用')) {
        Message({
          type: "warning",
          message: "提醒：您选中了一些非 “ 备用 ” 状态的SIM卡，这些卡将无法被作废。",
        });
      }
      this.selectionData = i
        .filter((d) => d.useStatus === '备用')
        .map((d) => d.simNumber);
    },
    onExport(){

    },
    onDelete() {
      let self = this;
      if (self.selectionData.length === 0) {
        self.$message.warning("请选中至少 1 张 “ 备用 ” 状态的SIM卡");
        return;
      } else {
        self.$msgbox
          .confirm("确定要将选中的SIM作废吗？", "提示", { type: "warning" })
          .then(async function() {
            self.btnLoading = true;
            try {
              await self.$api_hw.simManagement_obsoleteSimCard({
                simNumbers:self.selectionData
              });
              self.$notify({
                title: "成功",
                message: "操作成功",
                type: "success"
              });
              self.$nextTick(function() {
                self.$search(this.table);
              });
            } catch (e) {
              // console.error(e);
            }
          }).catch((e)=>{});
        self.btnLoading = false;
      }
    }
  },
  async mounted() {
    let self = this;
    BUS.$on(BUSEVENT.REFRESH_SIM_MANAGEMENT, () => {
      self.onRefresh();
    });
  }
};
</script>

<style lang='sass' scoped>
.btn
  width: 64px
  height: 28px
  border: 1px solid rgba(26,188,156,1)
  border-radius: 4px
  color: #1ABC9C
  
  .drawer-main
    margin: 5px 15px
    .drawer-btn
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(237,237,237,1)
      border-radius: 2px
      outline: none
      border: 0
      &:hover
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
      &:before
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
    .drawer-btn1
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(26,188,156,1)
      border-radius: 2px
      outline: none
      border: 1px solid transparent
      color: #fff

  .drawer-contract
    margin: 30px 15px 10px
    .drawer-contract-item
      width: 100%
      .drawer-contract-span
        color: #2E3033
    .drawer-contract-item1
      width: 48%
      .drawer-contract-span
        color: #2E3033
  .drawer-hr
    width: 100%
    height: 1px
    background: rgba(232,232,232,1)
  .drawer-map
    margin: 20px 15px 10px
    .drawer-map-title
      color: rgba(46,48,51,1)
  /deep/.el-drawer__header 
      margin-bottom: 15px
      padding-left: 15px
      color: #222
      font-size: 16px
  /deep/.el-button--success 
      color: #FFFFFF
      background-color: rgba(26,188,156,1)
      border-color: rgba(26,188,156,1)

</style>
